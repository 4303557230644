import { gradients } from "./colors";

const buttons = {
  buttons: {
    default: {
      display: "block",
      py: 2,
      px: 3,
      fontSize: [3, 2, 2],
      textAlign: "center",
      borderRadius: "4px",
      textTransform: "uppercase",
      textDecoration: "none",
      fontWeight: "button",
      //  width: ["fit-content"],
    },
    primary: {
      variant: "buttons.default",
      ...gradients.primary,
      color: "white",

      boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      outline: {
        variant: "buttons.default",
        color: "primary",
      },
      white: {
        variant: "buttons.default",
        bg: "white",
        color: "primary",
        boxShadow: "0px 4px 8px #99B4BC, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      },
    },
    secondary: {
      white: {
        variant: "buttons.default",
        bg: "white",
        color: "secondary",

        boxShadow: "0px 4px 8px #998383, 0px 2px 4px rgba(54, 123, 245, 0.24)",
      },
    },
    vimeo: {
      bg: "primary.gradient",

      fontSize: 5,
    },
  },
};

export default buttons;
