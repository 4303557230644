import buttons from "./buttons";
import cards from "./cards";
import colors, { gradients } from "./colors";
import forms from "./forms";
import { Hero } from "./hero";
import layout from "./layouts";
import spacing from "./spacing";
import typeography, {
  headingStyles,
  textStyles,
  lineHeights,
} from "./typeography";

const theme = {
  breakpoints: ["768px", "1025px", "1290px"],
  header: {
    zIndex: "999",

    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    height: ["50px", "70px"],
  },
  ...spacing,
  ...typeography,
  ...lineHeights,
  colors: { ...colors },

  text: textStyles,
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 2,
    },
    ...headingStyles,
    ...textStyles,
    blockQuote: {
      display: "block",
      borderWidth: "2px 0",
      borderStyle: "solid",
      borderColor: "#eee",
      padding: "1.5em 0 0.5em",
      margin: "1.5em 0",
      position: "relative",
      "::before": {
        content: "'\\201C'",
        position: "absolute",
        top: "0em",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#fff",
        width: "3rem",
        height: "2rem",
        font: "6em/1.08em 'PT Sans', sans-serif",
        color: "primary",
        textAlign: "center",
      },
    },
    ".video-container": {
      overflow: "hidden",
      position: "relative",
      height: 400,
      iframe: {
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
        borderRadius: "4px",
      },
    },
    ".partners": {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  gradients: { ...gradients },
  ...layout,
  ...buttons,
  ...cards,
  ...forms,
  hero: Hero,

  backgrounds: {
    primary: {
      bg: "primary",
      gradient: {
        ...gradients.primary,
        shadow: {
          ...gradients.primary,
          boxShadow:
            "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
        },
      },

      shadow: {
        ...gradients.primary,
        boxShadow:
          "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
      },
    },
    shadow: {
      boxShadow:
        "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
    },
  },
  quote: {
    variant: "backgrounds.primary.gradient.shadow",
  },

  links: {
    nav: {
      color: "primary",
      fontWeight: "thin",
      fontSize: [5, 3],
      "&.active": {
        fontWeight: "Bold",
      },
    },
  },
  modals: {
    primary: {
      backDrop: {
        zIndex: 9999,
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        bg: "modalBackdrop",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
        //pointerEvents: "none",
      },
      container: {
        position: "relative",
        bg: "primaryLightBG",
        p: 4,
        borderRadius: 4,
      },
      close: {
        position: "absolute",
        top: 0,
        right: 0,
        color: "primary",
      },
    },
    dark: {
      backDrop: {
        variant: "modals.primary.backDrop",
      },
      container: {
        variant: "modals.primary.container",
        bg: "primary",
      },
      close: {
        variant: "modals.primary.close",
        color: "primaryLight",
      },
    },
  },

  radix: {
    trigger: {
      all: "unset",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
      padding: "0 15px",
      fontSize: 13,
      lineHeight: 1,
      height: 35,
      gap: 5,
      backgroundColor: "white",
      color: "black",
    },
    content: {
      overflow: "hidden",
      backgroundColor: "white",
      borderRadius: 6,
      boxShadow:
        "0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)",
    },
    scrollUp: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",
      cursor: "default",
      height: "25px",
    },
    scrollDown: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",
      cursor: "default",
      height: "25px",
    },
    item: {
      fontSize: 13,
      lineHeight: 1,
      color: "black",
      borderRadius: 3,
      display: "flex",
      alignItems: "center",
      height: 25,
      padding: "0 35px 0 25px",
      position: "relative",
      userSelect: "none",
    },
    input: {
      all: "unset",
      width: "100%",
      fontSize: 13,
      lineHeight: 1,
      color: "black",
      borderColor: "white",
      bg: "white",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      //height: 25,
      px: 2,
      py: 2,
      position: "relative",
      userSelect: "none",
    },
    label: {
      color: "white",
    },
    message: {
      color: "yellow",
    },
  },
};

export default theme;
