//import { shadows } from "./colors";

const cardCommon = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  bg: "white",
  p: 4,
  //boxShadow: shadows.grey,
  borderRadius: "4px",
};

const cards = {
  cards: {
    primary: {
      bg: "white",
      //padding: 4,
      borderRadius: "4px",
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
    },
    radioIcon: {
      variant: "cards.primary",
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        variant: "cards.radioIcon.selected",
      },
      selected: {
        variant: "cards.primary",
        bg: "primary",
        color: "white",
        caption: {
          fontSize: 1,
          color: "red",
        },
      },
      caption: {
        fontSize: 1,
        color: "red",
      },
    },
    icon: {
      variant: "cards.primary",
      p: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "100px",
      bg: "primaryLightBG",
      h3: {
        width: "70%",
        fontWeight: "light",
        textAlign: "left",
      },
      svg: {
        background: `linear-gradient(90deg, #004257 0%, #004D4B 79.17%);`,

        fontSize: "80px",
        //borderRadius: "50%",
        color: "primaryLight",
        minHeight: "100px",
      },
    },
  },
};

export default cards;
