export const Hero = {
  background: {
    position: "absolute",
    top: 0,
    height: "560px",
    width: "100%",
    zIndex: -1,
  },
  contentWrapper: {
    width: ["100%", "80%"],
    justifyContent: "space-evenly",
    alignItems: ["center", null, null],
    display: "flex",
    color: "white",
    flexDirection: ["column", "row"],
  },
  h1: {
    display: "flex",
    flexDirection: ["row", "column"],
    fontWeight: ["bold", "body"],
    fontSize: [3, 6],
    textTransform: "uppercase",
    alignItems: ["center", "flex-start"],
    span: { lineHeight: "56px" },
  },
  message: {
    width: ["80%", "50%"],
    fontWeight: ["light", "thin"],
    fontSize: [3, 4],
    textAlign: ["center", "left"],
  },
};
